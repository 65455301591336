<script setup lang="ts">
/**
 * REFACTORED on 8/5/2023 by Danish
 */
import { Button, Dialog, TextInput } from "carmine-ui/components";
import LoginForm from "~/components/authentication/LoginForm.vue";
import SignUpForm from "~/components/authentication/SignUpForm.vue";

// Helpers
import moment from "moment";
import * as Sentry from "@sentry/vue";
import { validatePhoneNumber } from "~/helpers/validators";
import { formatNumber } from "~/helpers/formatter";

// Composables
import { useNotification } from "carmine-ui/composables";
import { useSignUpStore } from "~/pinia/user";
import { useAuth } from "carmine-auth-vue";
import { AxiosError } from "axios";

interface Props {
    /**
     * Allow Continue as Guest
     */
    allowGuest?: boolean;
    open: boolean;
    toggleFunction: () => void;
}

const props = defineProps<Props>();

const signUpStore = useSignUpStore();
const auth = useAuth();
const notification = useNotification();
const state = ref<"sign-up" | "login" | "guest" | "phone" | "otp">("login");

// OTPs
const otpCode = ref("");
const timeRemaining = ref(moment.duration());
const timer = ref<number | null>(null);
const isRequestAllowed = computed(() => !(timeRemaining.value.asSeconds() > 0));
const isProcessLoading = ref(false);

// Phone number states
const number = ref("");
const numberValidity = computed(() => {
    let isValid = false;
    let message = "";
    if (number.value == "") {
        isValid = false;
        message = "Contact number can't be empty";
    } else {
        isValid = validatePhoneNumber(number.value);
        if (!isValid) {
            message = "Enter a valid contact number (011-1234-4567)";
        }
    }
    return {
        isValid,
        message,
    };
});
const strippedNumber = computed(() => `0${number.value.replace(/-/g, "")}`);

/* CONTINUE AS GUEST */
const guestInfo = ref<{
    name: string;
    email: string;
    phone: string;
}>({
    name: "",
    email: "",
    phone: "",
});

/**
 * Continue as Guest
 */
function continueAsGuest() {
    const { name, email, phone } = guestInfo.value;
    isProcessLoading.value = true;
    auth.continueAsGuest(
        name,
        email,
        phone,
        () => {
            handleClose();
        },
        (error) => {
            let description =
                "There is an unexpected error occured. Please try again later";
            if (error.response) {
                if ((error.response.data as any).errors?.message) {
                    const message = (error.response.data as any).errors
                        ?.message;
                    if (
                        (message as string).includes(
                            "User is already registered"
                        )
                    ) {
                        description =
                            "User with this email is already registered with us. You may login into your account.";
                        notification.warning({
                            title: "User exists",
                            description,
                        });
                    }
                }
            } else {
                Sentry.captureException(error);
                notification.error({
                    title: "Oops, something happened",
                    description,
                });
            }
            handleClose();
        }
    );
}
/* CONTINUE AS GUEST */

function onOtp() {
    signUpStore.method = "email";
    state.value = "otp";
}

function handleGoogleSignUp() {
    signUpStore.method = "google";
    state.value = "phone";
}

/**
 * Request OTP
 */
function requestOTP() {
    if (signUpStore.method === "google") {
        signUpStore.user = {
            ...signUpStore.emptyState,
            phoneNumber: strippedNumber.value,
        };
    }
    auth.requestOTP(strippedNumber.value, onOtpRequested, onOtpRequestError);
}

/**
 * Called when a new OTP is requested
 */
function onOtpRequested() {
    state.value = "otp";

    // Notify
    notification.success({
        title: "We have sent you a new OTP",
        description: `We have sent you an OTP code to ${strippedNumber.value} to verify your account`,
    });

    timeRemaining.value = moment.duration(1, "minute");
    timer.value = setInterval(() => {
        timeRemaining.value.subtract(moment.duration(1, "second"));
    }, 1000);
}

/**
 * Called when OTP request fail
 */
function onOtpRequestError() {
    notification.error({
        title: "Oops, an error occurred",
        description:
            "There is a problem requesting your One-Time Password (OTP). Please try again later",
    });
}

/**
 * Submit OTP
 */
function submitOTP() {
    if (signUpStore.user) {
        isProcessLoading.value = true;
        auth.submitOTP(
            signUpStore.user.phoneNumber,
            otpCode.value,
            (response) => {
                if (signUpStore.user) {
                    if (signUpStore.method === "email") {
                        const { user } = signUpStore;
                        auth.signUp(
                            {
                                email: user.email,
                                password: user.password,
                                phoneNumber: user.phoneNumber,
                                name: user.name,
                                role: user.role,
                            },
                            login,
                            () => {
                                notification.error({
                                    title: "Oops, an error occurred!",
                                    description:
                                        "There is a problem signing you up, please try again later.",
                                });
                                isProcessLoading.value = false;
                            }
                        );
                        return;
                    }

                    if (signUpStore.method === "google") {
                        const { user } = signUpStore;
                        auth.signUpWithGoogle(
                            signUpStore.user.phoneNumber,
                            user.role
                        );
                        return;
                    }
                }
            },
            (error) => {
                let title = "Oops, an error occurred";
                let description =
                    "There is a problem processing your request, please try again later.";
                const data = error.response?.data as any;
                if (data) {
                    if (data.errors.message) {
                        const message = data.errors.message;

                        switch (message) {
                            case "Invalid OTP":
                                title = "Invalid OTP";
                                description =
                                    "Your One-Time Passcode is invalid, please confirm again. You may request a new one.";
                                break;
                        }
                    }
                }

                notification.error({
                    title,
                    description,
                });
                isProcessLoading.value = false;
            }
        );
    }
}

function handleClose() {
    //temporary fix (https://github.com/tailwindlabs/headlessui/issues/1000)
    //setTimeout(toggleFunction, 200);
    props.toggleFunction();
    state.value = "login";
    isProcessLoading.value = false;
}

function login() {
    const { user } = signUpStore;
    if (user) {
        auth.login(
            user.email,
            user.password,
            () => {
                notification.success({
                    title: "Welcome to CARMINE!",
                    description: "We are happy to see you!",
                });
                handleClose();
            },
            () => {
                notification.warning({
                    title: "Oops, something happened!",
                    description:
                        "There is a problem logging you in. Your account is created, please try logging in again.",
                });
                isProcessLoading.value = false;
            }
        );
    }
}

// Clear timer if timeRemaining is 0
watchEffect(() => {
    if (timeRemaining.value.asSeconds() === 0 && timer.value) {
        clearInterval(timer.value);
        timer.value = null;
    }
});

watchEffect(() => {
    const formatted = formatNumber(number.value);
    if (formatted) number.value = formatted;
});
</script>

<template>
    <Dialog :open="open" @close="!isProcessLoading && handleClose()">
        <div class="flex flex-col w-96 p-6">
            <!-- Sign-up template -->
            <template v-if="state == 'sign-up'">
                <div class="flex flex-col">
                    <h1 class="mb-2 text-lg font-medium text-gray-900 text-left">
                        Create an account
                    </h1>
                    <p class="mb-5 text-sm text-gray-500 text-left">
                        Welcome to CARMINE!
                    </p>
                    <p class="mb-5 text-gray-500 text-sm">
                        By signing up, I agree to CARMINE's
                        <router-link class="text-primary-700" to="/terms-of-use">Terms</router-link>
                        and
                        <router-link class="text-primary-700" to="/privacy-policy">
                            Privacy Policy</router-link>
                    </p>
                    <SignUpForm @otp="onOtp" @googleSignUp="handleGoogleSignUp" />
                    <p class="mt-8 text-sm text-gray-500 mx-auto text-center">
                        Already have an account?
                        <span class="text-sm text-primary-700 font-medium cursor-pointer"
                            @click="state = 'login'">Login</span>
                    </p>
                </div>
            </template>

            <!-- Login template -->
            <template v-if="state == 'login'">
                <div class="flex flex-col">
                    <h1 class="mb-2 text-lg font-medium text-gray-900 text-left">
                        Login
                    </h1>
                    <p class="mb-5 text-sm text-gray-500 text-left">
                        Welcome back to CARMINE!
                    </p>
                    <LoginForm @success="toggleFunction" />
                    <p class="mt-8 text-sm text-gray-500 mx-auto text-center">
                        <template v-if="allowGuest">
                            <span class="text-sm text-primary-700 font-medium cursor-pointer"
                                @click="state = 'guest'">Continue as Guest</span>
                            or
                        </template>
                        <template v-else> Don't have an account? </template>
                        <span class="text-sm text-primary-700 font-medium cursor-pointer" @click="state = 'sign-up'">Sign
                            Up</span>
                    </p>
                </div>
            </template>

            <!-- Continue as Guest -->
            <template v-if="state === 'guest'">
                <div class="flex flex-col">
                    <h1 class="mb-2 text-lg font-medium text-gray-900 text-left">
                        Continue as Guest
                    </h1>
                    <div class="flex flex-col gap-5 mb-8">
                        <p class="text-sm text-gray-500 text-left">
                            Welcome to CARMINE!
                        </p>
                        <TextInput v-model="guestInfo.name" label="Name" placeholder="Enter name" />
                        <TextInput v-model="guestInfo.email" label="Email" placeholder="Enter email" />
                        <TextInput v-model="guestInfo.phone" label="Phone Number" placeholder="Enter phone number" />
                    </div>
                    <Button variant="primary" :loading="isProcessLoading" @click="continueAsGuest">Continue</Button>
                </div>
            </template>

            <!-- Phone input template -->
            <template v-if="state == 'phone'">
                <div class="flex flex-col">
                    <h1 class="mb-2 text-lg font-medium text-gray-900 text-left">
                        Enter your phone number
                    </h1>
                    <TextInput v-model="number" class="mb-5" type="tel" label="Contact Number" placeholder="00-000-0000"
                        :required="true" :warn="!numberValidity.isValid && number != ''" :helper-text="number == ''
                                ? 'Malaysian numbers only'
                                : numberValidity.message
                            ">
                        <template #left>
                            <h1 class="text-md text-gray-900 text-left whitespace-nowrap">
                                MY (+60)
                            </h1>
                        </template>
                    </TextInput>
                    <Button variant="primary" :disabled="!numberValidity.isValid" :loading="auth.userAuth.value.isLoading"
                        @click="requestOTP">Request OTP</Button>
                </div>
            </template>

            <!-- OTP template -->
            <template v-if="state == 'otp'">
                <div class="flex flex-col">
                    <h1 class="mb-2 text-lg font-medium text-gray-900 text-left">
                        Check your SMS
                    </h1>
                    <p class="mb-4 tablet:mb-5 text-sm text-gray-500 text-left">
                        We have sent an OTP code to
                        {{ signUpStore.user?.phoneNumber }}
                    </p>
                    <TextInput v-model="otpCode" required label="OTP Code" placeholder="Enter the OTP code" class="mb-1.5"
                        :maxlength="6" />
                    <p class="text-sm text-gray-700 text-left mb-6">
                        Didn't get a code?
                        <span v-if="isRequestAllowed" class="text-primary-700 font-medium cursor-pointer"
                            @click="requestOTP">Click to resend</span>
                        <span v-else class="font-medium">Try again in
                            {{ timeRemaining.asSeconds() }} seconds</span>
                    </p>
                    <Button variant="primary" :disabled="otpCode === ''"
                        :loading="auth.userAuth.value.isLoading || isProcessLoading" @click="submitOTP">Verify OTP</Button>
                </div>
            </template>
        </div>
    </Dialog>
</template>

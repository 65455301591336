<script setup lang="ts">
import { Tooltip } from 'carmine-ui/components';

// Icon
import WhatsAppIcon from '~/assets/images/whatsapp.png'

function whatsappCarmine() {
    window.open("http://wa.me/60122149429", '_blank')
}
</script>

<template>
    <div id="whatsapp-us-button">
        <Tooltip dark arrow>
            <img :src="WhatsAppIcon" class="h-16 w-16 cursor-pointer bg-white rounded-full drop-shadow-md"
                @click="whatsappCarmine" />
            <template #tooltip>
                <span class="text-xs font-normal text-white">Contact Us</span>
            </template>
        </Tooltip>
    </div>
</template>
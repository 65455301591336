/**
 * Formats a non-dashed number into a dashed number
 * @param number Malaysian phone number
 * @returns Formatted number with dashes, or undefined if number is invalid
 */
export function formatNumber(number: string) {
    // Define the regular expression and replacement pattern
    const regex = /(\+?6?)?(01\d{1}|0[2-57-9])(\d{3,4})(\d{4})/g;
    const replacement = "$1$2-$3-$4";

    // Apply the regular expression and replacement pattern
    const formattedNumber = number.replace(regex, replacement);

    return formattedNumber;
}

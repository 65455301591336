import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { setContext } from "apollo-link-context";
import { verifyToken } from "carmine-auth-vue";
import * as Sentry from "@sentry/vue"

const httpLink = createHttpLink({
    uri: import.meta.env.VUE_APP_HASURA_URL,
    credentials: "same-origin",
});

const headers = async () => {
    const auth = localStorage.getItem('auth')
    if (auth) {
        const { token } = JSON.parse(auth)
        // Check for token validity, valid if expirationUnix is more than current Unix
        const isTokenValid = verifyToken()
        if (typeof token === 'string' && token.length > 0 && isTokenValid) {
            return {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }
    }

    return {
        "x-hasura-role": "anonymous",
        "Content-Type": "application/json",
    };
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const scope = new Sentry.Scope()
    scope.setLevel('error')
    scope.setExtra('query', operation.query.loc?.source.body)
    scope.setExtra('variables', operation.variables)
    if (graphQLErrors) {
        Sentry.captureMessage(`[ErrorLink] GraphQL Error : ${graphQLErrors.map(error => error.message).join(', ')}`, scope)
    }
    if (networkError) {
        Sentry.captureMessage(`[ErrorLink] Network Error : ${networkError.message}`, scope)
    };
});

const authLink = setContext(async (_) => {
    return {
        headers: await headers(),
    };
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink.concat(httpLink as any) as any]),
    cache,
});

<script setup lang="ts">
import { Button } from 'carmine-ui/components'
import GoogleIcon from '~/assets/icons/authentication/google.svg'
interface Props {
    social: 'Google',
    mode: 'Sign Up' | 'Login',
}

const { social, mode } = defineProps<Props>()

const label = computed(() => {
    return `${mode} with ${social}`
})

const iconMap = {
    'Google': GoogleIcon
}
const icon = computed<string>(() => iconMap[social])

</script>

<template>
    <Button variant="secondary" class="bg-white shadow-sm text-gray-700">
        <template #left>
            <img class="h-4" :src="icon" />
        </template>
        {{ label }}
    </Button>
</template>
import { INJECTION_KEYS } from "~/helpers/constants";

// Type
import type {
    AuthDialogShowFn,
    AuthDialogCloseFn,
} from "~/types/authentication";

export function useAuthDialog() {
    const show = inject<AuthDialogShowFn>(
        INJECTION_KEYS.authentication.dialog.show,
        () => {
            console.log("Show Auth Dialog Injection failed");
        }
    );
    const hide = inject<AuthDialogCloseFn>(
        INJECTION_KEYS.authentication.dialog.close,
        () => {
            console.log("Close Auth Dialog Injection failed");
        }
    );
    return {
        show,
        hide,
    };
}

export const INJECTION_KEYS = {
    authentication: {
        dialog: {
            show: "auth_dialog_show",
            close: "auth_dialog_close",
        },
    },
    bookmark: {
        refetch: "bookmark_refetch",
    },
};

import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
import { useAuth } from "carmine-auth-vue";
import { useNotification } from "carmine-ui/composables";
import _ from "lodash";

const routes: RouteRecordRaw[] = [
    {
        name: "home",
        path: "/",
        component: () => import("~/pages/home.vue"),
    },
    {
        name: "sign-up",
        path: "/sign-up",
        component: () => import("~/pages/authentication/sign-up.vue"),
        meta: {
            noAuthOnly: true,
        },
    },
    {
        name: "otp-verify",
        path: "/otp-verify",
        component: () => import("~/pages/authentication/otp-verify.vue"),
        meta: {
            noAuthOnly: true,
        },
    },
    {
        name: "authorize",
        path: "/authorize",
        component: () => import("~/pages/authentication/authorize.vue"),
    },
    {
        name: "log-in",
        path: "/log-in",
        component: () => import("~/pages/authentication/log-in.vue"),
        meta: {
            noAuthOnly: true,
        },
    },
    {
        name: "forgot",
        path: "/forgot",
        component: () => import("~/pages/authentication/forgot.vue"),
        meta: {
            noAuthOnly: true,
        },
    },
    {
        name: "reset",
        path: "/reset/:payload",
        component: () => import("~/pages/authentication/reset.vue"),
    },
    {
        name: "terms-of-use",
        path: "/terms-of-use",
        component: () => import("~/pages/terms-of-use.vue"),
    },
    {
        name: "privacy-policy",
        path: "/privacy-policy",
        component: () => import("~/pages/privacy-policy.vue"),
    },
    {
        name: "cars",
        path: "/buy-from-carmine",
        component: () => import("~/pages/cars/catalog.vue"),
        props: (route) => ({
            items: route.query.items,
            page: route.query.page,
            brand: route.query.brand,
            body: route.query.body,
            budget: route.query.budget,
            year: route.query.year,
            transmission: route.query.transmission,
            mileage: route.query.mileage,
            color: route.query.color,
            state: route.query.state,
            search: route.query.search,
        }),
    },
    {
        name: "cars-specific",
        path: "/buy-from-carmine/:brand/:slug/:id",
        component: () => import("~/layouts/BasePage.vue"),
        children: [
            {
                name: "cars-specific-landing",
                path: "",
                component: () => import("~/pages/cars/specific.vue"),
            },
            {
                name: "cars-specific-inspect",
                path: "inspect",
                component: () =>
                    import("~/pages/cars/specific/inspection-report.vue"),
            },
            {
                name: "cars-specific-book",
                path: "book",
                component: () => import("~/pages/cars/specific/book.vue"),
            },
            {
                name: "car-test-drive-success",
                path: "book/success",
                component: () =>
                    import(
                        "~/pages/cars/specific/test-drive-state/success.vue"
                    ),
            },
            {
                name: "car-test-drive-failed",
                path: "book/failed",
                component: () =>
                    import("~/pages/cars/specific/test-drive-state/failed.vue"),
            },
            {
                name: "car-lock-in-success",
                path: "lock-in/success",
                component: () =>
                    import("~/pages/cars/specific/lock-in-state/success.vue"),
            },
            {
                name: "car-lock-in-failed",
                path: "lock-in/failed",
                component: () =>
                    import("~/pages/cars/specific/lock-in-state/failed.vue"),
            },
            {
                name: "cars-specific-lock-in",
                path: "lock-in",
                component: () => import("~/pages/cars/specific/lock-in.vue"),
            },
        ],
    },
    {
        name: "financing-loan",
        path: "/financing/car-loan",
        component: () => import("~/pages/financing/loan.vue"),
    },
    {
        name: "financing-insurance",
        path: "/financing/car-insurance",
        component: () => import("~/pages/financing/insurance.vue"),
    },
    {
        name: "articles",
        path: "/articles",
        component: () => import("~/pages/articles/listing.vue"),
        props: (route) => ({
            tag: route.query.tag,
        }),
    },
    {
        name: "article",
        path: "/article",
        component: () => import("~/pages/articles/specific.vue"),
        props: (route) => ({ id: route.query.id }),
    },
    {
        name: "sell-car",
        path: "/sell-cars",
        component: () => import("~/layouts/BasePage.vue"),
        children: [
            {
                name: "sell-car-landing",
                path: "",
                component: () => import("~/pages/cars/sell/landing.vue"),
            },
        ],
    },
    {
        name: "sell-process",
        path: "/sell",
        component: () => import("~/pages/cars/sell/sell.vue"),
        children: [
            {
                name: "sell-process-landing",
                path: "",
                redirect: "sell-car",
            },
            {
                name: "sell-inspection",
                path: "inspection",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("~/layouts/EmptyRouterPage.vue"),
                children: [
                    {
                        name: "sell-inspection-landing",
                        path: "",
                        component: () =>
                            import("~/pages/cars/sell/inspection.vue"),
                    },
                    {
                        name: "sell-inspection-self",
                        path: "self",
                        component: () =>
                            import("~/pages/cars/sell/inspections/self.vue"),
                    },
                    {
                        name: "sell-inspection-book",
                        path: "book",
                        component: () =>
                            import("~/pages/cars/sell/inspections/book.vue"),
                    },
                    {
                        name: "sell-inspection-success",
                        path: "success",
                        component: () =>
                            import(
                                "~/pages/cars/sell/inspection-states/success.vue"
                            ),
                    },
                ],
            },
        ],
    },
    {
        name: "faq",
        path: "/faqs",
        component: () => import("~/pages/faq.vue"),
    },
    {
        name: "about-us",
        path: "/about-us",
        component: () => import("~/pages/about-us/landing.vue"),
    },
    {
        name: "buy-from-carmine",
        path: "/buying-from-carmine",
        component: () => import("~/pages/about-us/buy-from-carmine.vue"),
    },
    // {
    //     // Temporarily disabled
    //     name: "inspection-points",
    //     path: "/inspection-points",
    //     component: () => import("~/pages/about-us/inspection-points.vue"),
    // },
    {
        name: "contact-us",
        path: "/contact-us",
        component: () => import("~/pages/about-us/contact-us.vue"),
    },
    {
        name: "locate-us",
        path: "/locate-us",
        component: () => import("~/pages/about-us/locate-us.vue"),
    },
    {
        name: "our-moments",
        path: "/our-moments",
        component: () => import("~/pages/about-us/our-moments.vue"),
    },
    {
        name: "seller-appointment-reschedule",
        path: "/account/my-sell-car-appointments/seller/:id/reschedule",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import("~/pages/account/seller-appointment-reschedule.vue"),
    },
    {
        name: "user-appointment-reschedule",
        path: "/account/my-sell-car-appointments/user/:id/reschedule",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import("~/pages/account/user-appointment-reschedule.vue"),
    },
    {
        name: "account-base",
        path: "/account",
        component: () => import("~/pages/account/base.vue"),
        meta: {
            requiresAuth: true,
            roles: {
                blacklist: ["GUEST"],
            },
        },
        children: [
            {
                name: "my-account",
                path: "",
                component: () => import("~/pages/account/my-account.vue"),
            },
            {
                name: "my-appointments",
                path: "my-sell-car-appointments",
                component: () => import("~/pages/account/appointments.vue"),
            },
            {
                name: "my-bookmarks",
                path: "my-bookmarks",
                component: () => import("~/pages/account/bookmarks.vue"),
            },
            {
                name: "my-test-drives",
                path: "my-test-drives",
                component: () => import("~/pages/account/test-drives.vue"),
            },
            {
                name: "my-browsing-history",
                path: "my-browsing-history",
                component: () => import("~/pages/account/browsing-history.vue"),
            },
            {
                name: "my-orders",
                path: "my-orders",
                component: () => import("~/pages/account/orders.vue"),
            },
        ],
    },
    {
        name: "account-verify",
        path: "/account/verify",
        meta: {
            requiresAuth: true,
        },
        component: () => import("~/pages/account/verify.vue"),
    },
    {
        name: "account-delete",
        path: "/account/delete",
        meta: {
            requiresAuth: true,
        },
        component: () => import("~/pages/account/delete.vue"),
    },
    {
        name: "account-recover",
        path: "/account/recover",
        meta: {
            requiresAuth: true,
        },
        component: () => import("~/pages/account/recover.vue"),
    },
    {
        name: "account-password-change",
        path: "/account/change-password",
        meta: {
            requiresAuth: true,
        },
        component: () => import("~/pages/account/change-password.vue"),
    },
    {
        name: "not-found",
        path: "/:path(.*)*",
        component: () => import("~/pages/404.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // console.log(to, from);
        // Exists when Browser's back/forward pressed
        if (savedPosition) {
            return savedPosition;
            // For anchors
        }

        if (to.hash) {
            return { selector: to.hash };
        }

        if (from.path === to.path) {
            return {};
        }

        // Scroll to top
        return { top: 0 };
    },
});

/**
 * Authentication guard
 */
router.beforeEach(async (to, from) => {
    const auth = useAuth();
    const notify = useNotification();

    // Refresh token if available & no longer authed
    if (!auth.isAuthenticated.value) {
        try {
            await auth.refreshToken()
            return true
        } catch (error) {
            await auth.logout()
        }
    }

    if (to.meta.noAuthOnly && auth.isAuthenticated.value) {
        return {
            path: "/",
        };
    }

    if (to.meta.requiresAuth && !auth.isAuthenticated.value) {
        return {
            path: "/",
        };
    }

    if (_.has(to.meta, "roles.blacklist")) {
        if (
            ((to.meta.roles as any).blacklist as string[]).includes(
                auth.hasuraUser.value.role
            )
        ) {
            if (auth.hasuraUser.value.role === "GUEST") {
                notify.warning({
                    title: "Page not allowed",
                    description:
                        "You are using a Guest account, please register an account to continue.",
                });
                return {
                    path: "/",
                };
            }
        }
    }

    return true;
});

export default router;

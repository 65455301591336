<script setup lang="ts">
import AuthDialog from '~/components/dialogs/AuthDialog.vue';
import WhatsappUsButton from '~/components/buttons/WhatsappUsButton.vue';

// Composables
import { useAuth } from 'carmine-auth-vue';
import { useToggle } from 'carmine-ui/composables';
import { useHead } from "@vueuse/head"
import { useDatabaseTypes } from './pinia/database';
import { registerSwiper } from 'carmine-ui/components'

// Helpers
import * as Sentry from "@sentry/vue"
import { INJECTION_KEYS } from '~/helpers/constants'

// Type
import type { AuthDialogShowFn, AuthDialogCloseFn } from './types/authentication';

registerSwiper()

useHead({
    titleTemplate: (title?: string) => !title ? 'CARMINE - Buy Used Cars and Sell Car Platform' : `${title} - CARMINE`,
})
const auth = useAuth()
const { isShown: isAuthDialogShown, toggle: toggleAuthDialog } = useToggle()
const { isLoading: isTypesLoading } = useDatabaseTypes()

/* PROVIDE AUTH DIALOG */
const isContinueAsGuestAllowed = ref(false)
provide<AuthDialogShowFn>(INJECTION_KEYS.authentication.dialog.show, (guest?: boolean) => {
    isAuthDialogShown.value = true
    isContinueAsGuestAllowed.value = guest || false
})
provide<AuthDialogCloseFn>(INJECTION_KEYS.authentication.dialog.close, () => { isAuthDialogShown.value = false })
/* PROVIDE AUTH DIALOG */

// Set Sentry User 
watchEffect(() => {
    if (auth.isAuthenticated.value) {
        const user = auth.hasuraUser.value
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: user.id,
                username: user.name,
                email: user.email,
            })
        })
    } else {
        Sentry.setUser(null)
    }
})
</script>

<template>
    <template v-if="isTypesLoading">
        Loading
    </template>
    <template v-else>
        <router-view />
        <WhatsappUsButton class="fixed z-50 bottom-6 right-6" />
        <AuthDialog :allow-guest="isContinueAsGuestAllowed" :open="isAuthDialogShown" :toggle-function="toggleAuthDialog" />
    </template>
</template>

<style>
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.vueperslides__arrow svg {
    stroke-width: 2 !important;
}

.vueperslides__arrow {
    opacity: 1 !important;
}
</style>

<script setup lang="ts">
import { Button, TextInput, Checkbox } from "carmine-ui/components";
import SocialAuthButton from "~/components/authentication/SocialAuthButton.vue";

// Composables
import { useAuth } from "carmine-auth-vue";
import { useNotification } from "carmine-ui/composables";
import { useGetUserRoleAsAnonymousLazyQuery } from "~/graphql/types";
import { useSavedLogin } from "~/composables/storage";
import { useAuthDialog } from "~/composables/authentication";

// Helpers
import z from "zod";
import _ from "lodash";

const auth = useAuth();
const notification = useNotification();
const router = useRouter();
const savedLogin = useSavedLogin();
const authDialog = useAuthDialog()

/**
 * Login form schema
 */
const credentialSchema = z.object({
    email: z.string().email({
        message: "Your email format is incorrect",
    }),
    password: z
        .string()
        .regex(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            "Password must have at least 8 characters, an uppercase letter, a number"
        ),
});

/**
 * Login form state
 */
const credentials = ref<z.infer<typeof credentialSchema>>({
    email: savedLogin.value.email,
    password: savedLogin.value.password,
});

/**
 * Remember me state
 */
const isRememberEnabled = ref(savedLogin.value.email !== "");

const isLoginAllowed = computed(() => {
    let map = {};
    try {
        credentialSchema.parse(credentials.value);
        return true;
    } catch (error) {
        if (error instanceof z.ZodError) {
            error.issues.forEach((issue) => {
                map[issue.path[0]] = issue.message;
            });
        }
        return false;
    } finally {
        inputErrors.value = map;
    }
});

const inputErrors = ref<{
    [key: string]: string;
}>({});

/* LOGIN */
const {
    load: fetchUser,
    refetch: refetchUser,
    loading: isGetUserLoading,
} = useGetUserRoleAsAnonymousLazyQuery({
    email: credentials.value.email,
});

/**
 *  Login
 */
async function login() {
    try {
        const validCredentials = credentialSchema.parse(credentials.value);
        const user = await refetchUser({
            email: validCredentials.email,
        });

        if (user && user.data.users.length > 0) {
            // User exist
            auth.login(
                validCredentials.email,
                validCredentials.password,
                () => {
                    if (isRememberEnabled.value) {
                        savedLogin.value = credentials.value;
                    } else {
                        savedLogin.value = {
                            email: "",
                            password: "",
                        };
                    }
                    router.replace("/");
                    notification.success({
                        title: "Welcome!",
                        description: "We are eager to have you back!",
                    });
                    authDialog.hide()
                },
                (error) => {
                    // Safely check for the path
                    if (_.hasIn(error, "response.data.errors")) {
                        notification.error({
                            title: "Oops, there seems to be a problem",
                            description: (error.response?.data as any).errors
                                .error_description,
                        });
                    }
                }
            );
        } else {
            // User does not exist
            notification.error({
                title: "User does not exist",
                description:
                    "Your entered email is not registered with us, please sign-up to continue.",
            });
        }
    } catch (error) {
        notification.error({
            title: "Oops, something happened!",
            description:
                "There was an unexpected error occurred. Please try again later.",
        });
    }
}

/**
 * Login with Google
 */
function loginGoogle() {
    auth.loginWithGoogle();
}
/* LOGIN */

onMounted(() => {
    fetchUser(undefined, {
        email: "",
    });
});
</script>

<template>
    <div class="flex flex-col">
        <TextInput v-model="credentials.email" :disabled="auth.userAuth.value.isLoading"
            :warn="credentials.email !== '' && inputErrors.email"
            :helper-text="credentials.email !== '' && inputErrors.email" class="mb-5" label="Email"
            placeholder="Enter your email" />
        <TextInput v-model="credentials.password" :disabled="auth.userAuth.value.isLoading"
            :warn="credentials.password !== '' && inputErrors.password"
            :helper-text="credentials.password !== '' && inputErrors.password" class="mb-6" type="password" label="Password"
            placeholder="Enter your password" />
        <div class="mb-6 flex flex-row justify-between items-center">
            <Checkbox v-model="isRememberEnabled" label="Remember Me" />
            <RouterLink to="/forgot" class="text-sm font-medium text-primary-700">Forgot Password</RouterLink>
        </div>
        <Button variant="primary" class="mb-4" :disabled="!isLoginAllowed"
            :loading="auth.userAuth.value.isLoading || isGetUserLoading" @click="login">Login</Button>
        <SocialAuthButton social="Google" mode="Login" @click="loginGoogle" />
    </div>
</template>

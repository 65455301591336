export const validateEmail = (email: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

// Number without +6 0 is optional
export const validatePhoneNumber = (number: string) => {
    let str = number.replace(/-/g, "");
    return /^0?(1[0-9]{1,2})([0-9]{3})([0-9]{4})$/.test(str);
};

export const validatePassword = (password: string) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
};

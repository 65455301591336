import { createApp } from "vue";
import { createPinia } from "pinia";
import VueAwesomePaginate from "vue-awesome-paginate";
import vueSvg from "vue-svg-inline-plugin";
import VueTransition from "@morev/vue-transitions";
import VueStorage from "vue3-storage";
import VueScrollTo from "vue-scrollto";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueSocialSharing from "vue-social-sharing";
import VCalendar from "v-calendar";
import piniapersist from "pinia-plugin-persistedstate";
import { createGtm } from "@gtm-support/vue-gtm";
import { createHead } from "@vueuse/head";
import CarmineAuth from "carmine-auth-vue";
import { CUIPlugin } from "carmine-ui/composables";

// Styles
import "v-calendar/dist/style.css";
import "vueperslides/dist/vueperslides.css";
import "carmine-ui/css";
import "@vueform/slider/themes/default.css";
import "./index.css";

// Apollo
import {
    DefaultApolloClient,
    provideApolloClient,
} from "@vue/apollo-composable";
import { apolloClient } from "./helpers/apollo";
import { createApolloProvider } from "@vue/apollo-option";

// Global components
import ContentContainerVue from "~/layouts/ContentContainer.vue";
import VueUploadComponent from "vue-upload-component";
import Divider from "~/components/Divider.vue";

// Sentry
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import prismic from "~/prismic/prismic";

// Options API for Apollo
provideApolloClient(apolloClient);
const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

const app = createApp(App);

Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    enabled: import.meta.env.MODE !== "development",
    // debug: true,
    tracePropagationTargets: [
        "localhost",
        "carmine.my",
        "dev-retail.carmine.my",
    ],
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
                routeLabel: "path",
            }),
        }),
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            mask: ['[type="password"]'],
        })
    ],
    sendDefaultPii: true,
    // Tracks child components
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: 0.7, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const pinia = createPinia();
pinia.use(piniapersist);

app.provide(DefaultApolloClient, apolloClient);
app.use(apolloProvider);
app.use(pinia);
app.use(router);
app.use(CarmineAuth, {
    baseURL: import.meta.env.VUE_APP_AUTH_SERVER_BASE_URL,
});
app.use(CUIPlugin, {
    emailBaseUrl: import.meta.env.VUE_APP_EMAIL_SERVER_BASE_URL,
    imageKitUrl: import.meta.env.VUE_APP_IMAGEKIT_URL,
    router,
});
app.use(
    createGtm({
        id: "GTM-5TNPXR7",
        vueRouter: router,
    })
);
app.use(VueAwesomePaginate);
app.use(vueSvg);
app.use(VueTransition);
app.use(VueStorage, {});
app.use(VueScrollTo);
app.use(prismic);
app.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    },
});
app.use(VueSocialSharing);
app.use(VCalendar, {});
app.use(createHead());

app.component("content-container", ContentContainerVue);
app.component("file-upload", VueUploadComponent);
app.component("divider", Divider);
app.mount("#app");

<script setup lang="ts">
import { Button, TextInput, SvgIcon } from "carmine-ui/components";
import SocialAuthButton from "~/components/authentication/SocialAuthButton.vue";

// Composables
import { useAuth } from "carmine-auth-vue";
import { useSignUpStore } from "~/pinia/user";
import { useGetUserRoleAsAnonymousLazyQuery } from "~/graphql/types";

// Helpers
import z from "zod";
import _ from "lodash";
import * as Sentry from '@sentry/vue'

// Icons
import ArrowRight from "~/assets/icons/arrow-right.svg";
import { computedAsync } from "@vueuse/core";

interface Events {
    (e: "otp"): void;
    (e: "error"): void;
    (e: "googleSignUp"): void;
}

const emit = defineEmits<Events>();

const auth = useAuth();
const signUpStore = useSignUpStore();

const credentialSchema = z.object({
    name: z.string(),
    email: z
        .string()
        .email({
            message: "Your email format is incorrect",
        })
        .refine(async (email) => {
            const user = await refetchUser({
                email,
            });
            return !(user && user.data.users.length > 0);
        }, "This email has already been used"),
    number: z
        .string()
        .regex(
            /^(1[0-9]{1,2})-?([0-9]{3})-?([0-9]{4})$/,
            "Must be a Malaysian number"
        )
        .transform((number) => `+60${number.replace(/-/g, "")}`),
    password: z
        .string()
        .regex(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            "Password must have at least 8 characters, an uppercase letter, a number"
        ),
});

const credentials = ref<z.infer<typeof credentialSchema>>({
    name: "",
    email: "",
    number: "",
    password: "",
});

const isSignUpAllowed = computedAsync(async () => {
    let map = {};
    try {
        await credentialSchema.parseAsync(credentials.value);
        return true;
    } catch (error) {
        if (error instanceof z.ZodError) {
            error.issues.forEach((issue) => {
                map[issue.path[0]] = issue.message;
            });
        }
        return false;
    } finally {
        inputErrors.value = map;
    }
}, false);

const inputErrors = ref<{
    [key: string]: string;
}>({});

/* SIGN UP */
/**
 * Sign up
 */
async function signUp() {
    try {
        const { name, email, password, number } = await credentialSchema.parseAsync(
            credentials.value
        );

        // Save data to pinia
        signUpStore.user = {
            name,
            email,
            phoneNumber: number,
            password,
            role: "CUSTOMER",
        };

        auth.requestOTP(
            number,
            () => {
                emit("otp");
            },
            () => {
                emit("error");
            }
        );
    } catch (error) {
        Sentry.captureException(error)
    }
}

/**
 * Initiate google sign up
 */
function signUpGoogle() {
    signUpStore.method = "google";
    emit("googleSignUp");
}
/* SIGN UP */

/**
 * Formats the phone number into dashes
 */
function formatInput() {
    let match = credentials.value.number.match(
        /^0?(1[0-9]{1,2})([0-9]{3})([0-9]{4})$/
    );
    if (match) credentials.value.number = `${match[1]}-${match[2]}-${match[3]}`;
}

/* CHECK FOR EXISTING USER */
const { load: fetchUser, refetch: refetchUser } =
    useGetUserRoleAsAnonymousLazyQuery({
        email: credentials.value.email,
    });
/* CHECK FOR EXISTING USER */

onMounted(() => {
    fetchUser();
});
</script>

<template>
    <div class="flex flex-col">
        <TextInput v-model="credentials.name" class="mb-5" label="Name" placeholder="Enter your name"
            :disabled="auth.userAuth.value.isLoading" />
        <TextInput v-model="credentials.email" class="mb-5" type="email" :disabled="auth.userAuth.value.isLoading"
            :warn="credentials.email !== '' && Boolean(inputErrors.email)"
            :helper-text="credentials.email !== '' && inputErrors.email" label="Email" placeholder="Enter your email" />
        <TextInput v-model="credentials.number" class="mb-5" type="tel" label="Contact Number" placeholder="00-000-0000"
            :disabled="auth.userAuth.value.isLoading" :warn="credentials.number !== '' && Boolean(inputErrors.number)"
            :helper-text="credentials.number !== '' && inputErrors.number" @focusout="formatInput" aria-autocomplete="none">
            <template #left>
                <h1 class="text-md text-gray-900 text-left whitespace-nowrap">
                    MY (+60)
                </h1>
            </template>
        </TextInput>
        <TextInput v-model="credentials.password" class="mb-6" type="password" label="Password"
            placeholder="Create a password" :disabled="auth.userAuth.value.isLoading"
            :warn="credentials.password !== '' && Boolean(inputErrors.password)"
            :helper-text="credentials.password !== '' && inputErrors.password" />
        <Button variant="primary" class="mb-4 text-white self-stretch" @click="signUp" :disabled="!isSignUpAllowed"
            :loading="auth.userAuth.value.isLoading">
            <template #left>
                <SvgIcon :src="ArrowRight" class="stroke-2 stroke-white" />
            </template>
            Get Started
        </Button>
        <SocialAuthButton mode="Sign Up" social="Google" @click="signUpGoogle" />
    </div>
</template>

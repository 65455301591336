import { defineStore } from "pinia";
import type { Writeable } from "~/types/general";
import type { USER_CREDENTIALS } from "~/types/authentication";

export const useSignUpStore = defineStore("sign-up-user", () => {
    const initialState = undefined;
    const emptyState = {
        email: "",
        password: "",
        phoneNumber: "",
        name: "",
        imageUrl: "",
        role: "CUSTOMER",
    };

    const user = ref<Writeable<USER_CREDENTIALS> | undefined>(initialState);
    const method = ref<"email" | "google">("email");

    function reset() {
        user.value = initialState;
        method.value = "email";
    }

    return { emptyState, user, method, reset };
});
